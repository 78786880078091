import Datadog from "@bounce/datadog"
import { App } from "@bounce/util"

import { DEFAULT_APPSFLYER_HOST } from "../constants"
import { OpenAppStore } from "../types"

interface AttributionParams {
  utmSource: string
  utmCampaign: string
  utmMedium?: string
  utmTerm?: string
  utmContent?: string
}

interface GenerateDeepLinkParams {
  app: App
  attributionParams: AttributionParams
  path?: string
  anonymousUserId?: string
}

type EnrichedDeepLinkParams = GenerateDeepLinkParams & {
  pathUri: URL
  pathQueryParams: Record<string, string>
  path: string
}

interface TokenCookie {
  accessToken: string
  refreshToken: string
  exp: number
}

const getAuthTokenFromCookie = (): TokenCookie | null => {
  const cookies = document.cookie.split("; ").reduce((acc: Record<string, string>, cookieString: string) => {
    const [key, value] = cookieString.split("=")
    if (!key || !value) return acc
    acc[key] = decodeURIComponent(value)
    return acc
  }, {})

  const authTokenString = cookies.AUTH_TOKEN
  if (authTokenString) {
    try {
      const authToken = JSON.parse(authTokenString)
      return {
        accessToken: authToken.accessToken,
        refreshToken: authToken.refreshToken,
        exp: authToken.expiry,
      }
    } catch (error) {
      void Datadog.addError(error)
      return null
    }
  }
  return null
}

// Main const to =  generate deep links
export const generateDeepLink = (
  params: GenerateDeepLinkParams,
  options: { openAppStore?: OpenAppStore; autoLogin?: boolean } = {},
): string => {
  const openAppStore = options.openAppStore || OpenAppStore.MobileOnly
  const autoLogIn = options.autoLogin || false
  const tokens = autoLogIn ? getAuthTokenFromCookie() : null

  const normalizedPath = normalizePath(params.path, getBaseAppPath(params.app))
  const pathUri = new URL(normalizedPath, getBaseAppHost(params.app))
  const pathQueryParams: Record<string, string> = Object.fromEntries(new URLSearchParams(pathUri.search).entries())

  return buildUrl({ attrs: { ...params, pathUri, pathQueryParams, path: normalizedPath }, openAppStore, tokens })
}

const buildUrl = ({
  attrs,
  openAppStore,
  tokens,
}: {
  attrs: EnrichedDeepLinkParams
  openAppStore: OpenAppStore
  tokens: TokenCookie | null
}): string => {
  const uri = new URL(getPath(attrs.app), `https://${DEFAULT_APPSFLYER_HOST}`)
  uri.search = buildQuery({ attrs, openAppStore, tokens })
  return uri.toString()
}

const getPath = (app: App): string => {
  const paths = {
    [App.Customer]: "/A49J/",
    [App.Packages]: "/p2Rw/",
    [App.Partner]: "/IqAS/",
  }
  return paths[app] || "/"
}

const buildQuery = ({
  attrs,
  openAppStore,
  tokens,
}: {
  attrs: EnrichedDeepLinkParams
  openAppStore: OpenAppStore
  tokens: TokenCookie | null
}): string => {
  const deepLinkValue = buildDeepLinkValue(attrs, tokens)
  const query: Record<string, string | null> = {
    deep_link_value: deepLinkValue,
  }

  addMobileAttributionParams(query, attrs)
  addRedirects({ acc: query, attrs, openAppStore, tokens })

  return new URLSearchParams(Object.entries(query).filter(([, v]) => v !== null) as [string, string][]).toString()
}

const buildDeepLinkValue = (attrs: EnrichedDeepLinkParams, tokens: TokenCookie | null): string => {
  const loginPath = getLoginPath(attrs.app)
  if (tokens) {
    const params = getDeepLinkQuery({ queryParams: attrs.pathQueryParams, path: attrs.path, tokens })
    const query = new URLSearchParams(params).toString()

    return `${loginPath}?${query}`
  }
  return attrs.path
}

const addMobileAttributionParams = (
  acc: Record<string, string | null>,
  { attributionParams, anonymousUserId }: GenerateDeepLinkParams,
): void => {
  acc.pid = attributionParams.utmSource
  acc.c = attributionParams.utmCampaign

  if (anonymousUserId) {
    acc.cuid = anonymousUserId
  }
}

const addRedirects = ({
  acc,
  attrs,
  openAppStore,
  tokens,
}: {
  acc: Record<string, string | null>
  attrs: EnrichedDeepLinkParams
  openAppStore: OpenAppStore
  tokens: TokenCookie | null
}): void => {
  if (tokens) {
    // Auto-login case, add af_web_dp for mobile and af_r for desktop only when auto-login is enabled
    acc.af_web_dp = getRedirectUrl(attrs, tokens)
  } else {
    // Only add af_r or af_web_dp if it's necessary based on the openAppStore option
    if (openAppStore === OpenAppStore.Never) {
      acc.af_r = getRedirectUrl(attrs, tokens)
    } else if (openAppStore === OpenAppStore.MobileOnly) {
      acc.af_web_dp = getRedirectUrl(attrs, tokens)
    }
  }
}

const getRedirectUrl = (attrs: EnrichedDeepLinkParams, tokens: TokenCookie | null): string => {
  const loginPath = getLoginPath(attrs.app)
  const finalPath = tokens ? loginPath : attrs.pathUri.pathname
  const redirectUri = new URL(finalPath, getBaseAppHost(attrs.app))

  const redirectQuery = {
    ...getWebAttributionParams(attrs.attributionParams),
    ...getDeepLinkQuery({ queryParams: attrs.pathQueryParams, path: attrs.path, tokens }),
  }
  redirectUri.search = new URLSearchParams(redirectQuery).toString()

  return redirectUri.toString()
}

const getWebAttributionParams = (params: AttributionParams): Record<string, string> => {
  return {
    utm_source: params.utmSource,
    utm_campaign: params.utmCampaign,
    ...(params.utmMedium && { utm_medium: params.utmMedium }),
    ...(params.utmTerm && { utm_term: params.utmTerm }),
    ...(params.utmContent && { utm_content: params.utmContent }),
  }
}

const getDeepLinkQuery = ({
  queryParams,
  path,
  tokens,
}: {
  queryParams: Record<string, string>
  path: string
  tokens: TokenCookie | null
}): Record<string, string> => {
  return tokens
    ? {
        returnRoute: path,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        expiry: tokens.exp.toString(),
        ...queryParams,
      }
    : queryParams
}

const normalizePath = (path: string | undefined, appBasePath: string): string => {
  let normalizedPath = path?.startsWith("/") ? path.slice(1) : path
  const normalizedAppBasePath = appBasePath.startsWith("/") ? appBasePath.slice(1) : appBasePath
  if (normalizedPath === normalizedAppBasePath || normalizedPath?.startsWith(`${normalizedAppBasePath}/`)) {
    normalizedPath = normalizedPath.slice(normalizedAppBasePath.length)
  }
  normalizedPath = normalizedPath?.startsWith("/") ? normalizedPath.slice(1) : normalizedPath
  return `/${[normalizedAppBasePath, normalizedPath].filter(Boolean).join("/")}`
}

const getBaseAppHost = (app: App): string => {
  switch (app) {
    case App.Customer:
      return "https://bounce.com"
    case App.Partner:
      return "https://partner.bounce.com"
    case App.Packages:
      return "https://bounce.com"
    default:
      throw new Error("Invalid app")
  }
}

const getBaseAppPath = (app: App): string => {
  switch (app) {
    case App.Customer:
      return "/s"
    case App.Partner:
      return ""
    case App.Packages:
      return "/packages"
    default:
      throw new Error("Invalid app")
  }
}

const getLoginPath = (app: App): string => {
  const basePath = getBaseAppPath(app)
  return `${basePath}/login`
}
