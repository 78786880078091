import { generateDeepLink, OpenAppStore } from "@bounce/attribution"
import { App } from "@bounce/util"

export type GetAppStoreUrlOptions = {
  app: App
  campaignName?: string
  anonymousUserId?: string
}

const getDeepLinkPath = (app: App) => {
  switch (app) {
    case App.Customer:
      return "/s"
    case App.Partner:
      return ""
    case App.Packages:
      return "/packages/location"
    default:
      throw Error("Invalid app")
  }
}

/**
 * Generate and return a URL for the specified app in the App Store, tailored to the user's platform.
 * If no platform is specified, the function will auto-detect the user's platform and return the URL accordingly.
 * The function also includes campaign tracking parameters to track the performance of various marketing campaigns.
 *
 * @param {GetAppStoreUrlOptions} options - Configuration options for the function.
 * @param {string} options.app - The bounce app for which to generate a store URL. Can be "luggageStorage", "packages", or "partner".
 * @param {string} [options.platform] - The platform for which to generate the URL. Can be "ios" or "android". If not specified, it will be auto-detected.
 * @param {object} options.campaign - An object containing the tracking parameters for the marketing campaign. This differs for iOS and Android.
 * @returns {string} The fully assembled App Store URL, including campaign tracking parameters.
 */
export const getAppStoreUrl = ({
  app,
  campaignName = "bounce-marketing-ctas",
  anonymousUserId,
}: GetAppStoreUrlOptions) => {
  return generateDeepLink(
    {
      app,
      path: getDeepLinkPath(app),
      attributionParams: {
        utmSource: "marketing",
        utmCampaign: campaignName,
      },
      anonymousUserId,
    },
    { openAppStore: OpenAppStore.Always },
  )
}
