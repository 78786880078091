import classNames from "classnames"
import * as React from "react"

import { Paragraph2 } from "@bounce/design-system"

export type AlertBannerType = "ALERT" | "INFO" | "SUCCESS" | "REGULAR"

export type AlertBannerProps = {
  message: string
  leftIcon?: React.ReactElement<{ className: string }>
  rightIcon?: React.ReactElement<{ className: string }>
  type?: AlertBannerType
  // Styles
  viewClassName?: string
  messageClassName?: string
}

const iconClassNameByType: { [key in AlertBannerType]: string } = {
  REGULAR: "text-black",
  ALERT: "text-red-700",
  SUCCESS: "text-green-700",
  INFO: "text-primary",
}

const viewClassNameByType: { [key in AlertBannerType]: string } = {
  REGULAR: "bg-neutral-100",
  ALERT: "bg-red-200",
  SUCCESS: "bg-green-200",
  INFO: "bg-primary-100",
}

const AlertBanner = ({
  message,
  leftIcon,
  rightIcon,
  type = "REGULAR",
  // Styles
  viewClassName = "",
  messageClassName = "",
}: AlertBannerProps) => (
  <div
    role="status"
    className={classNames(
      "flex flex-row items-center rounded-md px-2.5 py-2",
      viewClassNameByType[type],
      viewClassName,
    )}>
    {/* Left icon */}
    {leftIcon &&
      React.cloneElement(leftIcon, {
        className: classNames("mr-1.5", iconClassNameByType[type]),
      })}
    {/* Message */}
    <Paragraph2 className={["text-neutral-900", messageClassName]}>{message}</Paragraph2>
    {/* Right icon */}
    {rightIcon &&
      React.cloneElement(rightIcon, {
        className: classNames("ml-1.5", iconClassNameByType[type]),
      })}
  </div>
)

export default AlertBanner
