import classNames from "classnames"

import { Analytics } from "@bounce/analytics"
import { Paragraph2 } from "@bounce/design-system"
import { useTranslation } from "@bounce/i18n"
import { App } from "@bounce/util"
import { Button, PNImage } from "@bounce/web-components"

import { BANNER_VARIANT, useAppStickyBanner, useIsAppStickyBannerVisible } from "../../../contexts/AppStickyBanner"
import { useAppStoreUrl } from "../../../hooks/useAppStoreUrl"
import { InteractionEvents } from "../../../libs/analytics/events"

import PhoneUiImage from "@bounce/assets/big-icons/phone.png"
import CloseIcon from "@bounce/assets/icons/Cross.svg"

const AppStickyBanner = () => {
  const { t } = useTranslation()
  const { href } = useAppStoreUrl({
    app: App.Customer,
    eventProps: { location: "AppStickyBanner", cta: "easier" },
    campaignName: "marketing-AppStickyBanner",
  })
  const visible = useIsAppStickyBannerVisible()
  const dismissBanner = useAppStickyBanner(state => state.dismissBanner)

  const ctaText = t("cmp.appStickyBanner.cta", "Use app")
  const shouldWrapText = ctaText.length && ctaText.length > 10

  const onClose = () => {
    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerDismissed, { variant: BANNER_VARIANT })
    dismissBanner()
  }

  const onPressUseApp = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerCTAPressed, { variant: BANNER_VARIANT })

    location.assign(ev.currentTarget.href)
  }

  if (!visible) {
    return null
  }

  return (
    <div className="flex max-w-full flex-row justify-between gap-2 border-b border-neutral-300 bg-white p-4 md:hidden">
      <div className={classNames("flex grow flex-row justify-between gap-2", shouldWrapText && "flex-wrap")}>
        <div className="flex flex-row items-center gap-4">
          <PNImage
            width={64}
            height={64}
            className="size-12"
            src={PhoneUiImage}
            alt={t("cmp.appStickyBanner.easier.imageAlt", "Hand holding a phone")}
            loading="eager"
          />
          <div>
            <div className="text-sm font-bold">{t("cmp.appStickyBanner.easier.title", "It's easier on the app!")}</div>
            <Paragraph2 className="text-neutral-700">
              {t("cmp.appStickyBanner.easier.description", "Access your booking on the go.")}
            </Paragraph2>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Button href={href} rel="nofollow" className="min-h-0 px-3 py-2 text-sm" onClick={onPressUseApp}>
            {ctaText}
          </Button>
        </div>
      </div>
      <button onClick={onClose}>
        <CloseIcon className="size-5" />
      </button>
    </div>
  )
}

export default AppStickyBanner
